<template>
  <section>
    <PageMetadata />
    <PageCanonical v-if="prods.total > 0" :canonical="{ totalRecords: prods.total }" />
    <flash-alert class="my-2" v-if="!embed" v-model:show="flashAlert.show" :level="flashAlert.level"> 
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <loading-overlay :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <template v-if="!embed">
      <h1 class="pb-0 mb-4 px-0">{{ getMetaDetails.page_h1 }}</h1>
      <component :is="'style'" v-if="getMetaDetails.additional_style != null && getMetaDetails.additional_style != ''">
        {{ getMetaDetails.additional_style }}
      </component>
      <div class="content_below_heading" v-if="!!getMetaDetails.description" v-html="getMetaDetails.description"></div>
      <after-hours-counter v-if="program.id" :programId="program.id"></after-hours-counter>
      <div v-for="(disclaimer, Key) in disclaimers" :key="Key" v-html="disclaimer"></div>
    </template>
    <section class="mt-4" v-if="!isLoggedIn || availableDates.length > 0">
      <div v-if="program.require_login && isLoggedIn || !program.require_login">
        <div v-if="displayProducts">
          <div class="row">
            <div class="col-12 offset-lg-2 col-lg-6">
              <div class="row align-items-center my-40 text-center text-lg-start" v-if="isLoggedIn && availableDates.length > 0">
                <h5 id="delivery-date-label" class="col-lg pe-lg-0 col-12 bolder-size mb-3 mb-lg-0">{{ this.deliveryDateLabel }}</h5>
                <div class="col-lg-auto col-12">
                  <div class="input-group d-md-inline-flex align-items-center delivery-date-row">
                    <div class="calender_container mx-auto">
                      <delivery-date-picker v-model:dateProp="filters.tmpDate" @deliveryDateChanged="processDateChange"
                        :minDate="minDate" :maxDate="maxDate" :disabledDates="disabledDates" pageKey="mmtDeliveryDate" />
                    </div>
                  </div>
                </div>
                <div v-if="deliveryDateDescription" class="text-center text-lg-start">{{ this.deliveryDateDescription }}</div>
                <cutoffs v-if="availableDates.length" :specificProg="true" :cutOffs="cutOffs[filters.date]"
                  :eligibleProgs="displayCutoffForPrograms" :isMMT="true" />
              </div>
            </div>
          </div>
          <div class="row mb-4 align-items-center" v-if="displayProducts">
            <div class="offset-lg-2 col-lg-3 mb-0 text-center text-lg-start">
              <b
                ><label class="mb-0 p-0"
                  >Total Products:
                  <span id="total_products">{{ prods.total }}</span></label
                ></b
              >
            </div>
            <div class="col-lg-7 mt-2 mt-lg-0">
              <div class="row gx-3 justify-content-between">
                <div class="mt-0 col-5 col-sm-auto">
                  <div
                    class="
                      delivery-address
                      d-flex
                      align-items-center
                      justify-content-between
                      mb-2 mb-sm-0
                    "
                  >
                    <label class="mb-0 p-0" for=""><b>Show:</b></label>
                    <div class="d-flex flex-grow-1">
                      <div
                        class="
                          d-flex
                          justify-content-around
                          align-items-center
                          p-0
                        "
                      >
                        <select
                          class="form-select"
                          v-model.number="filters.perPage"
                        >
                          <option value="16">16</option>
                          <option value="48">48</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-0 col-7 col-sm-auto">
                  <div
                    class="
                      delivery-address
                      d-flex
                      align-items-center
                      justify-content-between
                      mb-2 mb-sm-0
                    "
                  >
                    <label class="mb-0 p-0" for=""><b>Sort By:</b></label>
                    <div class="d-flex flex-grow-1">
                      <select class="form-select" v-model="filters.sortBy">
                        <option value="Name-ASC">Name (A - Z)</option>
                        <option value="Name-DESC">Name (Z - A)</option>
                        <option v-if="isLoggedIn" value="Price-ASC">
                          Price/Unit Asc
                        </option>
                        <option v-if="isLoggedIn" value="Price-DESC">
                          Price/Unit Desc
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    col-12 col-sm-auto
                    mt-1 mt-sm-0
                    align-self-center
                    text-center
                    ms-md-2
                  "
                >
                  <div id="grid-list-btn-container" class="d-inline-flex">
                    <Popper :arrow="true" :hover="true" content="List">
                      <button
                        type="button"
                        id="list-view"
                        @click="changeView('list')"
                        class="btn btn-default"
                      >
                        <i class="fa-solid fa-table-list"></i>
                      </button>
                    </Popper>

                    <Popper :arrow="true" :hover="true" content="Grid">
                      <button
                        type="button"
                        id="grid-view"
                        @click="changeView('grid')"
                        class="btn btn-default"
                      >
                        <i class="fa-solid fa-table-cells-large"></i>
                      </button>
                    </Popper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="displayProducts" class="row">
          <div class="col-lg-2">
            <div class="sidebar">
              <div class="mb-4 text-center text-md-start">
                <input
                  type="button"
                  id="reset-btn"
                  class="btn btn-theme"
                  value="Reset"
                  @click="resetFilters"
                />
              </div>

              <div class="mb-4 input-group" v-if="!embed">
                <input
                  type="text"
                  class="form-control width-0"
                  aria-label="Search"
                  aria-describedby="button-addon2"
                  v-model="filters.search"
                  @keyup.enter="applySearch"
                />
                <button
                  class="btn btn-theme"
                  type="button"
                  id="button-addon2"
                  @click="applySearch"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>

              <div
                v-for="(accordian, accordKey) in filterSection"
                :class="['mb-1', accordKey == currentAccordion ? 'open' : '']"
                :key="accordKey"
              >
                <div class="sidebar-heading" v-on:click="expand(accordKey)">
                  <p>{{ accordian.label }} <i class="fa-solid fa-plus"></i></p>
                </div>
                <template
                  v-for="(filterss, filterGroup) in accordian"
                  :key="filterGroup"
                >
                  <template v-if="filterGroup != 'label'">
                    <div
                      class="filter-container pt-0 px-2 px-md-0"
                      :class="{ 'mh-100': filterGroup == 'filter_color_combo' }"
                    >
                      <ul
                        class="filters"
                        :class="{
                          'bubble_color_wrapper my-1':
                            filterGroup == 'filter_color_combo',
                        }"
                      >
                        <li
                          :class="{
                            bubble_color: filterGroup == 'filter_color_combo',
                          }"
                          v-for="(filter, key) in filterss"
                          :key="key"
                        >
                          <a
                            v-if="'image' in filter && filter.image != '' && filter.image != null"
                            href="#"
                            @click.prevent="
                              setFilterCriteria({
                                group: filterGroup,
                                value: filter.id,
                                label: filter.label,
                              })
                            "
                          >
                            <Popper
                              :arrow="true"
                              :hover="true"
                              :content="filter.label"
                            >
                              <!-- <img
                              :class="[
                                'filter-color',
                                filter.border,
                                filterGroup in filters.criteria &&
                                filter.id in filters.criteria[filterGroup]
                                  ? filter.checkmark
                                  : '',
                              ]"
                              :src="`${imagePath}` + filter.image"
                            /> -->

                              <span
                                :class="[
                                  'filter-color',
                                  'filter-image',
                                  filter.border,
                                  filterGroup in filters.criteria &&
                                  filter.id in filters.criteria[filterGroup]
                                    ? filter.checkmark
                                    : '',
                                ]"
                                :style="{
                                  height: '22px',
                                  width: '22px',
                                  display: 'block',
                                  'border-radius': '50%',
                                  'background-image':
                                    `${imagePath}` + filter.image,
                                }"
                                ><img
                                  :class="[
                                    'filter-color',
                                    filter.border,
                                    filterGroup in filters.criteria &&
                                    filter.id in filters.criteria[filterGroup]
                                      ? filter.checkmark
                                      : '',
                                  ]"
                                  :src="`${imagePath}` + filter.image"
                              /></span>
                            </Popper>
                          </a>
                          <a
                            v-else-if="'hex' in filter && filter.hex != '' && filter.hex != null"
                            href="#"
                            @click.prevent="
                              setFilterCriteria({
                                group: filterGroup,
                                value: filter.id,
                                label: filter.label,
                              })
                            "
                          >
                            <Popper
                              :arrow="true"
                              :hover="true"
                              :content="filter.label"
                            >
                              <span
                                :class="[
                                  'filter-color',
                                  filter.border,
                                  filterGroup in filters.criteria &&
                                  filter.id in filters.criteria[filterGroup]
                                    ? filter.checkmark
                                    : '',
                                ]"
                                class=""
                                :style="{
                                  height: '22px',
                                  width: '22px',
                                  display: 'block',
                                  'border-radius': '50%',
                                  'background-color': filter.hex,
                                }"
                              ></span>
                            </Popper>
                          </a>
                          <a v-else-if="'href' in filter" :href="filter.href">
                            {{ filter.label }}
                          </a>
                          <template v-else>
                            <input
                              type="checkbox"
                              :value="filter.id"
                              :checked="
                                filterGroup in filters.criteria &&
                                filter.id in filters.criteria[filterGroup]
                              "
                              @change="
                                setFilterCriteria(
                                  {
                                    group: filterGroup,
                                    value: filter.id,
                                    label: filter.label,
                                  },
                                  $event
                                )
                              "
                            />{{ filter.label }}
                          </template>
                        </li>
                      </ul>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="col-lg-10" v-if="prods.total > 0" id="">
            <pagination-row
              :recordsCount="prods.total"
              :perPage="filters.perPage"
              :currPage="filters.pageNumb"
              @pageChange="changePage"
            />
            <div id="selected-filters-list-top">
              <template
                v-for="(criteria, group) in filters.criteria"
                :key="group"
              >
                <span v-for="(val, key) in criteria" :key="key">
                  {{ val }}
                  <a href="#" @click.prevent="removeFilterCriteria(group, key)"
                    >x</a
                  >
                  &nbsp;
                </span>
              </template>
            </div>
            <div
              class="product-grid-list-wrapper cart-page-table-wrapper"
              v-if="view == 'grid'"
              id="view-grid"
            >
              <div class="row">
                <product-card-m-m-t
                  v-for="(product, index) in prods.data"
                  :key="index"
                  :prodId="product"
                  :product="product"
                  :deliveryDate="filters.date"
                  @addToWl_pc="addToWlAfterMath"
                ></product-card-m-m-t>
              </div>
            </div>
            <div
              class="product-wrapper"
              id="view-list"
              v-else-if="view == 'list'"
            >
              <div v-if="isLoggedIn" class="card product-card border-0">
                <div class="card-header bg-grey d-none d-lg-block text-white">
                  <div class="row mx-0">
                    <div class="col-auto product-img-wrap"></div>
                    <div class="col product-name pl-0">Product Name</div>
                    <div class="col col-lg-auto lot-size">Available</div>
                    <div class="col col-lg-auto price">Price/Unit</div>
                    <div class="col col-lg-auto lot-price">Price</div>
                    <div class="col-auto action"></div>
                  </div>
                </div>

                <!-- Code for new design table to div  -->

                <div
                  v-for="(product, index) in prods.data"
                  :key="index"
                  class="card-body position-relative"
                >
                  <div class="row no-gutters">
                    <a class="p-0 w-auto m-0">
                      <Popper v-if="product.highlight_id" :arrow="true" :hover="true" :content="`${product.highlight_name}`">
                        <div class="ribbon2">
                          <span :style="`border-color: ${product.highlight_hex_code}; background: ${product.highlight_hex_code}; background: linear-gradient(${ product.highlight_hex_code } 0%, ${ product.highlight_hex_code } 100%);`">{{ product.highlight_name }}</span>
                        </div>
                      </Popper>
                    </a>
                    <div
                      class="
                        col-auto
                        product-img-wrap
                        position-relative
                        no-overflow
                      "
                    >
                      <img
                        v-if="product.image"
                        :src="product.image"
                        class="m-0 zoom"
                      />
                      <img
                        v-else
                        :src="
                          `${imagePath}` +
                          getDefaultImage(product.product_type_id)
                        "
                        class="m-0"
                      />
                      <add-to-wishlist
                      v-if="isLoggedIn"
                      :prod="product"
                      class="fr-wish"
                    ></add-to-wishlist>
                    </div>

                    <div class="col product-name  pl-0">
                      <div class="tool-tip">
                        <Popper
                          class="breakdown-popper"
                          v-if="
                            product.breakdown != null &&
                            product.breakdown.length > 2
                          "
                          :arrow="true"
                          :hover="true"
                        >
                          <template #content
                            ><div
                              v-html="
                                getBreakdown(
                                  product.breakdown,
                                  product.box_unit_singular
                                )
                              "
                            ></div>
                          </template>
                          <span class="star-color"
                            ><i class="fa-solid fa-star" aria-hidden="true"></i
                          ></span>
                        </Popper>
                        <span class="d-inline-block highlight-description" v-if="product.highlight_description">
                          <Popper :arrow="true" :hover="true" :content="product.highlight_description" style="margin:0; border: none;">
                            <i class="fa-solid fa-circle-info info-icon"></i>
                          </Popper>
                        </span>
                        {{ product.name }}
                        <span v-if="product.country_id > 0">&nbsp; &nbsp;</span>
                        <span
                          class="us_img_icon d-inline-block"
                          v-if="product.country_id > 0"
                        >
                          <Popper
                            :arrow="true"
                            :hover="true"
                            :content="product.country_label"
                          >
                            <img :src="`${imagePath}` + product.country_flag" />
                          </Popper>
                        </span>
                        <Popper v-if="product.special" :arrow="true" :hover="true" :content="`${product.discount * 100}% Off`">
                          <div class="ms-2 badge bg-peach">
                            <span>{{ product.discount * 100 }}% Off</span>
                          </div>
                        </Popper>
                        <br />
                        <span><i>{{ product.mmt_origin_name }}</i></span>
                      </div>
                    </div>
                    <div class="w-100 d-block d-lg-none"></div>
                    <div v-if="isLoggedIn" class="col col-lg-auto lot-size">
                      <div>
                        <label class="d-block d-lg-none"> {{ product.unit_quantity}} Available</label>
                        {{ product.box_name_singular }}
                        <br />
                        <span
                          v-if="
                            product.box_name_singular !=
                            product.box_unit_singular
                          "
                          >({{ product.unit_count }}
                          {{
                            product.unit_count > 1
                              ? product.box_unit_plural
                              : product.box_unit_singular
                          }})</span
                        >
                        <template>
                          {{
                            product.unit_count > 1
                              ? product.box_unit_plural
                              : product.box_unit_singular
                          }}
                        </template>
                      </div>
                    </div>
                    <div class="col col-lg-auto price text-md-left">
                      <div>
                        <label class="d-block d-lg-none">Price/Unit</label>
                        <b
                          >${{
                            $filters.numberFormat(product.price_per_stem)
                          }}
                          / {{ product.box_unit_singular }}</b
                        >
                      </div>
                    </div>
                    <div class="col col-lg-auto lot-price text-md-left">
                      <div>
                        <label class="d-block d-lg-none">Price</label>
                        ${{ $filters.numberFormat(product.price_per_unit) }} /
                        {{ product.box_name_singular }}
                      </div>
                    </div>

                    <div
                      class="action text-right"
                      :class="[isLoggedIn ? 'col-auto' : 'col-12']"
                    >
                      <div>
                        <label class="d-none d-md-block d-lg-none"
                          >&nbsp;</label
                        >
                        <div
                          v-if="isLoggedIn"
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <add-to-cart
                            :prod="product"
                            :min="product.min_qty"
                            :step="product.inc_qty"
                            :deliveryDate="filters.date"
                          />
                        </div>
                        <div
                          v-else
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <router-link class="btn btn-theme ml-1" :to="'/login'"
                            >LOGIN</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Code end   -->
              </div>
              <div v-else class="card product-card border-0">
                <div class="card-header not-logged bg-grey d-none d-lg-block text-white">
                  <div class="row no-gutters">
                    <div class="col-auto product-img-wrap"></div>
                    <div class="col product-name pl-0">Product Name</div>
                    <div class="col col-lg-auto lot-size" v-if="isLoggedIn"></div>
                    <div class="col col-lg-auto price" v-if="isLoggedIn"></div>
                    <div class="col col-lg-auto lot-price" v-if="isLoggedIn"></div>
                    <div class="col-auto action"></div>
                  </div>
                </div>

                <!-- Code for new design table to div  -->

                <div
                  v-for="(product, index) in prods.data"
                  :key="index"
                  class="card-body"
                >
                  <div class="row no-gutters">
                    <div class="col-auto product-img-wrap position-relative">
                      <img
                        v-if="product.image"
                        :src="product.image"
                        class=""
                        :alt="product.name"
                      />
                      <img
                        v-else
                        :src="
                          `${imagePath}` +
                          getDefaultImage(product.product_type_id)
                        "
                        class=""
                        :alt="product.name"
                      />
                    </div>
                    <div class="col product-name pl-0">
                      <div>
                        {{ product.name }}
                      </div>
                    </div>
                    <div class="w-100 d-block d-lg-none" v-if="isLoggedIn"></div>
                    <div v-if="isLoggedIn"
                      class="
                        d-none d-lg-block
                        col col-lg-auto
                        lot-size
                        text-center
                      "
                    >
                      <label class="d-block d-lg-none">Available</label>
                    </div>
                    <div v-if="isLoggedIn"
                      class="
                        d-none d-lg-block
                        col col-lg-auto
                        price
                        text-center text-md-left
                      "
                    >
                      <label class="d-block d-lg-none">Price/Unit</label>
                    </div>
                    <div v-if="isLoggedIn"
                      class="
                        d-none d-lg-block
                        col col-lg-auto
                        lot-price
                        text-center text-md-left
                      "
                    >
                      <label class="d-block d-lg-none">Price</label>
                    </div>
                    <div
                      class="action text-right"
                      :class="[isLoggedIn ? 'col-auto' : 'col-12']"
                    >
                      <div>
                        <label class="d-none d-md-block d-lg-none"
                          >&nbsp;</label
                        >
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <router-link class="btn btn-theme ml-1" :to="'/login'"
                            >LOGIN</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Code end   -->
              </div>
            </div>
            <div class="mt-10">
              <pagination-row
                :recordsCount="prods.total"
                :perPage="filters.perPage"
                :currPage="filters.pageNumb"
                @pageChange="changePage"
              />
            </div>
            <!-- <div id="pagination-list">
            <ul class="pagination justify-content-end mt-1"><li class="page-item active"><a class="page-link" href="JavaScript:Void(0);"><span>1</span></a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=2">2</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=3">3</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=4">4</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=5">5</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=6">6</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=7">7</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=8">8</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=9">9</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=2">&gt;</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=17">&gt;|</a></li></ul>
        </div> -->
          </div>
          <div v-if="filters.criteria && prods.total < 1" class="col-lg-10">
            <div id="selected-filters-list-top">
              <template
                v-for="(criteria, group) in filters.criteria"
                :key="group"
              >
                <span v-for="(val, key) in criteria" :key="key">
                  {{ val }}
                  <a href="#" @click.prevent="removeFilterCriteria(group, key)"
                    >x</a
                  >
                  &nbsp;
                </span>
              </template>
            </div>
            <p class="text">
              The filters you have selected do not match any available products.
              Remove or select a different filter combination to view our
              selection of fresh blooms.
            </p>
          </div>

          <div
            id="page_content_below"
            class="mt-10 content-wrapper"
            v-if="!embed && !!getMetaDetails.description_below_dynamic"
            v-html="getMetaDetails.description_below_dynamic"
          ></div>
          <recently-viewed v-if="!embed" />
        </div>
        <div v-else>Currently, we do not have inventory</div>
      </div>
      <div v-else>
        <p class="regular-p">Kindly <a href="/login">log-in</a> to see the available products for your branch. If you are currently not a Mayesh Wholesale customer, complete the <a href="/register">registration form </a>to our list of specials.</p>
      </div>
    </section>
  </section>
</template>

<script>
import {
  PaginationRow,
  ProductCardMMT,
  FlashAlert,
  AddToCart,
  DeliveryDatePicker,
  RecentlyViewed,
  Cutoffs,
  AddToWishlist,
  PageMetadata,
  PageCanonical,
  AfterHoursCounter,
} from "@/components";
import axios from "axios";
import { misc, cutoff } from "@/mixins";
import { mapGetters } from "vuex";
import Popper from "vue3-popper";

export default {
  name: "MassMarkets",
  mixins: [misc, cutoff],
  props: {
    embed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PaginationRow,
    ProductCardMMT,
    FlashAlert,
    AddToCart,
    DeliveryDatePicker,
    Popper,
    RecentlyViewed,
    Cutoffs,
    AddToWishlist,
    PageMetadata,
    PageCanonical,
    AfterHoursCounter
  },
  mounted() {
    localStorage.setItem("currentPage", "luxe-blooms-grocery");
    this.fetchProgramDetails();
    this.fetchProductsTypes();
    let site_settings = JSON.parse(localStorage.getItem("site_settings"));
    this.us_message = site_settings.us_flag_message;
    this.fetchMetaDetails();
    this.fetchStaticContents();
    // this.fetchRecentlyViewed();
    this.fetchDisclaimers();

    if ("perPage" in this.$route.query || "d_perPage" in this.$route.query) {
      this.triggerWatch = false;
      this.filters.perPage = parseInt(
        this.$route.query.perPage ?? this.$route.query.d_perPage,
        10
      );
    }
    if ("sortBy" in this.$route.query || "d_sortBy" in this.$route.query) {
      this.filters.sortBy =
        this.$route.query.sortBy ?? this.$route.query.d_sortBy;
    }
    if ("pageNumb" in this.$route.query || "d_pageNumb" in this.$route.query) {
      this.filters.pageNumb = parseInt(
        this.$route.query.pageNumb ?? this.$route.query.d_pageNumb,
        10
      );
      setTimeout(() => {
        this.pageLoaded = true;
      }, 1000);
    }
    if ("criteria" in this.$route.query || "d_criteria" in this.$route.query) {
      this.filters.criteria = JSON.parse(
        this.$route.query.criteriaInt ?? this.$route.query.d_criteriaInt
      );
    }
    if ("search" in this.$route.query || "l_search" in this.$route.query) {
      this.filters.search = decodeURI(
        this.$route.query.search
          ? this.$route.query.search
          : this.$route.query.l_search != undefined
          ? this.$route.query.l_search
          : ""
      );
      if (this.filters.search == undefined) this.filters.search = "";
    }
    if (this.getMmtDeliveryDate) {
      this.filters.tmpDate = this.getMmtDeliveryDate;
      this.processDateChange();
    } else if ("date" in this.$route.query || "d_date" in this.$route.query) {
      this.filters.tmpDate = this.$route.query.date ?? this.$route.query.d_date;
      this.filters.tempDate = this.$route.query.date ?? this.$route.query.d_date;
      this.processDateChange();
    }

    this.$nextTick(() => {
      this.triggerWatch = true;
    });

    this.fetchDates();
    this.fetchFilters();

    this.emitter.on("a2c", (payload) => {
      this.addToCartAfterMath(payload);
    });

    this.emitter.on("deletedProduct", () => {
      this.fetchProducts();
    });
    this.emitter.on("cartExpiredReload", () => {
      this.cartExpired();
    });
  },
  unmounted() {
    this.emitter.off("a2c");
    this.emitter.off("deletedProduct");
    this.emitter.off("cartExpiredReload");
  },
  data() {
    return {
      us_message: "US Product",
      productTypes: [],
      currentAccordion: "",
      availableDates: [],
      disabledDates: [],
      mixDate: '',
      maxDate: '',
      isLoading: false,
      fullPage: true,
      loader: "bars",
      displayProducts: true,
      filterSection: {},
      pageStaticContent: {},
      prods: {
        total: 0,
        data: [],
      },
      filters: {
        perPage: this.getPerPage(),
        sortBy: this.getSortBy(),
        pageNumb: 1,
        tmpDate: "",
        date: "",
        criteria: {},
      },
      view: localStorage.getItem("viewType")
        ? localStorage.getItem("viewType")
        : "list",
      triggerWatch: true,
      pageLoaded: false,
      disclaimers: {},
      cutOffs: {},
      displayCutoffForPrograms: [],
      program: {
        id: JSON.parse(localStorage.getItem("site_settings"))
          .mass_markets_id,
        display_cutoff: false,
        require_login: false
      },
      deliveryDateLabel: '',
      deliveryDateDescription: '',
      programGenericInfo: {}
    };
  },
  computed: {
    ...mapGetters(["getMmtDeliveryDate", "isSalesRep", "isESales"]),

    apiBody() {
      const criteria = {};
      for (let group in this.filters.criteria) {
        criteria[group] = Object.keys(this.filters.criteria[group]);
      }

      return {
        perPage: this.filters.perPage,
        sortBy: this.filters.sortBy,
        pageNumb: this.filters.pageNumb,
        date: this.filters.date,
        is_sales_rep: this.isSalesRep,
        is_e_sales: this.isESales,
        criteria,
        criteriaInt: this.filters.criteria,
        search: this.filters.search,
      };
    },
  },
  methods: {
    fetchProgramDetails() {
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/getProgramGenericInfo",
          {
            program_id: this.program.id,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.programGenericInfo = response.data.programInfo[0]
          this.program.display_cutoff = response.data.programInfo[0].display_cutoff;
          this.program.require_login = response.data.programInfo[0].require_login;
        });
    },

    cartExpired() {
      this.fetchProducts();
    },
    expand(accordionIndex) {
      if (this.currentAccordion == accordionIndex) this.currentAccordion = "";
      else this.currentAccordion = accordionIndex;
    },

    getPerPage() {
      let val;
      if (localStorage.getItem("perPage"))
        val = localStorage.getItem("perPage");
      if (val) {
        return val;
      }
      let all_site_settings = JSON.parse(localStorage.getItem("site_settings"));
      val = all_site_settings.default_products_per_page
        ? all_site_settings.default_products_per_page
        : 100;
      return parseInt(val, 10);
    },

    getBreakdown(breakdownJson, box_in_unit) {
      let breakdowns = JSON.parse(breakdownJson);
      let str = "";
      let unit_for_bd = "";
      if (breakdowns.length > 0) {
        str =
          '<table class="tbl_breakdown"><thead><tr><td colspan=3 align="center">Breakdown – Subject to Change</td></tr><thead><tbody><tr class="popper-content-header"><td align="left">Product</td><td align="right">Units</td></tr>';
        breakdowns.forEach((breakdown) => {
          if (box_in_unit == "stem")
            unit_for_bd = breakdown.bunches * breakdown.stemsBunch;
          else unit_for_bd = breakdown.bunches;

          // unit_for_bd = unit_for_bd + " " + box_in_unit;
          str =
            str +
            '<tr><td align="left">' +
            breakdown.productDescription +
            "</td>" +
            '<td align="right">' +
            unit_for_bd +
            " " +
            box_in_unit +
            "</td></tr>";
        });

        str = str + "</table>";
      }
      return str;
    },

    getSortBy() {
      if (localStorage.getItem("sortBy")) return localStorage.getItem("sortBy");
      else return "Name-ASC";
    },

    changePage(pageNumb) {
      this.filters.pageNumb = pageNumb;
    },
    setFilterCriteria(opt, evt = "") {
      if (!(opt.group in this.filters.criteria)) {
        this.filters.criteria[opt.group] = {};
      }

      let isChecked = true;
      if (evt === "") {
        isChecked = !(opt.value in this.filters.criteria[opt.group]);
      } else {
        isChecked = evt.target.checked;
      }

      if (isChecked) {
        this.filters.criteria[opt.group][opt.value] = opt.label;
      } else {
        this.removeFilterCriteria(opt.group, opt.value);
      }
    },
    removeFilterCriteria(group, key) {
      delete this.filters.criteria[group][key];
    },
    resetFilters() {
      this.triggerWatch = false;
      this.filters.perPage = 100;
      this.filters.sortBy = "Name-ASC";
      this.filters.search = "";
      this.$nextTick(() => {
        this.filters.criteria = {};
        this.triggerWatch = true;
      });
    },
    fetchProducts() {
      if (this.isLoggedIn && this.filters.date == "") {
        return false;
      }

      this.isLoading = true;

      let endpoint = "getMmtInventory";
      if (!this.isLoggedIn) {
        endpoint = "getAllMmtInventory"
      } else {
        this.filters.sortBy = this.filters.sortBy.replace("Price","Name");
        localStorage.setItem("sortBy", this.filters.sortBy);
      }

      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/" + endpoint,
          {
            filters: this.apiBody,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.prods.data = response.data.products;
          this.prods.total = response.data.total;

          let routeName = "massMarkets";
          let query = {
            ...this.apiBody,
          };
          query.criteria = JSON.stringify(query.criteria);
          query.criteriaInt = JSON.stringify(query.criteriaInt);
          query.l_search = query.search;
          query.search = "";

          if (this.pageLoaded && this.filters.pageNumb > 1 && this.filters.pageNumb > Math.ceil(this.prods.total/query.perPage)) {
            this.filters.pageNumb = 1;
          }
          
          this.$router.push({
            name: routeName,
            query: { ...query },
            hash: this.$route.hash,
          });
          this.isLoading = false;
          this.viewProductListToGA()
        });
    },
    viewProductListToGA() {
      let products = this.prods.data
      
        const items = [];
        products.forEach((prod) => {
          items.push({
            item_id: prod.product_id,
            item_name: prod.name,
            affiliation: prod.mmt_origin_name,
            item_list_id: this.programGenericInfo.id,
            item_list_name: this.programGenericInfo.name,
            item_category: prod.category_name,
            item_category2: prod.variety_name,
            item_variant: prod.color_name,
            item_category3: prod.grade_name,
            price: parseFloat(prod.price),
            price_after_coupon: prod.price > 0 ? parseFloat(prod.price) : parseFloat(prod.price),
            currency: "USD",
          });
        });
      
        let productObj = {
          item_list_id: this.programGenericInfo.id,
          item_list_name: this.programGenericInfo.name,
          items: items
        };
        this.$gtag.event("view_item_list", productObj);
    },
    fetchProductsTypes() {
      axios
        .get(
          process.env.VUE_APP_API_BASE + "auth/getProductTypes",
          {}, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.productTypes = response.data.types;
          localStorage.setItem(
            "productTypes",
            JSON.stringify(response.data.types)
          );
        });
    },

    fetchDates() {
      if (this.isLoggedIn) {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getMmtDates",
            {},
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(({ data }) => {
            const cutOffs = {};
            let isDefaultSet = false;

            if (data.error == false) {
              data.disabled_dates.forEach((disabledDate) => {
                const formatted = this.appendTimeForBrowser(disabledDate);
                this.disabledDates.push({
                  start: formatted,
                  end: formatted,
                  date: disabledDate,
                });
              });
              this.minDate = this.appendTimeForBrowser(data.min_date);
              this.maxDate = this.appendTimeForBrowser(data.max_date);
              data.dates.forEach((availDate) => {
                this.prepareCutoffObjForMassMarkets(cutOffs, availDate);
                
                if (!isDefaultSet && this.filters.date && availDate["delivery_date"] === this.filters.date) {
                  isDefaultSet = true;
                }
              });

              if (data.dates) {
                this.availableDates = data.dates;
              }
              this.cutOffs = cutOffs;
            }
            

            if (!this.availableDates.length) {
              if (!this.embed) {
                const errMsg = "Currently there are no delivery dates available in your area to deliver your request. To ensure you get the freshest product, please check back later to place your order.";
                this.triggerFlashAlert(errMsg, "error");
              }
              this.displayProducts = false;
            } else {
              if (!isDefaultSet) {
                this.filters.tmpDate = this.availableDates[0]['delivery_date'];
                this.processDateChange();
              } else {
                this.processDateChange();
              }
            }
          });
      }
    },

    fetchFilters() {
      let endpoint = "getMmtFilters";
      if (!this.isLoggedIn) {
        endpoint = "getAllMmtFilters";
      }
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/" + endpoint,
          { filters: {
              search: this.filters.search,
              date: this.apiBody.date
            }
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((data) => {
          this.filterSection = data.data.filters;

          const criteriaNew = {};
          for (const key in this.filters.criteria) {
            for (const key2 in this.filterSection) {
              if (key in this.filterSection[key2]) {
                criteriaNew[key] = {};
                for (const key3 in this.filters.criteria[key]) {
                  criteriaNew[key][key3] = this.filters.criteria[key][key3];
                }
                break;
              }
            }
          }

          this.filters.criteria = criteriaNew;

          if (this.program.display_cutoff) {
            this.displayCutoffForPrograms.push(parseInt(this.program.id));
          }
        });
    },

    fetchStaticContents() {
      // let  apiUrl = process.env.VUE_APP_API_BASE + "auth/getPageSettings";
      // axios
      //   .post(
      //     apiUrl,
      //     {
      //       slug: 'shop',
      //     }, // the data to post
      //     {
      //       headers: {
      //         "Content-type": "application/json",
      //         Authorization: "Bearer " + localStorage.getItem("token"),
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     this.pageStaticContent = response.data.page
      //     this.metaTitle = 'let me check'
      //     this.metaDescription = 'This is description'
      //   });
    },

    fetchDisclaimers() {
      let apiUrl = "";
      if (this.isLoggedIn) {
        apiUrl = process.env.VUE_APP_API_BASE + "auth/getProgramDisclaimers";
        axios
          .post(
            apiUrl,
            {
              page: "MMT",
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((data) => {
            this.disclaimers = data.data.disclaimers;
          });
      }
    },

    changeView(viewType) {
      this.view = viewType;
      localStorage.setItem("viewType", this.view);
    },

    addToWlAfterMath(addStatus) {
      let flashMsg = "";
      if (!addStatus) {
        flashMsg = "You must be logged in!";
      } else {
        flashMsg = "Successfully added to the WL!";
      }

      this.triggerFlashAlert(flashMsg);
    },
    addToCartAfterMath(response) {
      if (response.level == "success") {
        this.fetchProducts();
        this.triggerFlashAlert(response.msg, response.level);
      } else {
        this.triggerFlashAlert(response.msg, response.level);
      }
    },
    processDateChange() {
      this.filters.date  = this.formatDateWithZeroFromStr(this.appendTime(this.filters.tmpDate));
      if (this.triggerWatch) {
        this.clearCartComments();
      }
      this.updateDeliveryDateLabel();
    },
    applySearch() {
      this.filters.pageNumb = 1;
      this.fetchProducts();
    },
    updateDeliveryDateLabel() {
      let apiUrl = process.env.VUE_APP_API_BASE + "auth/getDeliveryDateLabel";
      if (this.isLoggedIn && this.program.id > 0) {
        axios
        .post(
          apiUrl,
          {
            program_id: this.program.id,
            delivery_date: this.filters.tmpDate,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((data) => {
          if (data) {
            this.deliveryDateLabel = data.data.deliveryDateLabel;
            this.deliveryDateDescription = data.data.deliveryDateDescription;
          }
        });
      } else {
        this.deliveryDateLabel = 'SELECT DELIVERY DATE';
        this.deliveryDateDescription = '';
      }
    },
  },
  watch: {
    "filters.perPage": function () {
      if (this.triggerWatch) {
        this.filters.pageNumb = 1;
        localStorage.setItem("perPage", this.filters.perPage);
        this.fetchProducts();
      }
    },
    "filters.sortBy": function () {
      if (this.triggerWatch) {
        this.filters.pageNumb = 1;
        localStorage.setItem("sortBy", this.filters.sortBy);
        this.fetchProducts();
      }
    },
    "filters.pageNumb": function () {
      if (this.triggerWatch) {
        this.fetchProducts();
      }
    },
    "filters.date": function () {
      if (this.triggerWatch) {
        if (this.displayProducts) {
          this.fetchProducts();
          this.fetchFilters();
        }
      }
    },
    "filters.criteria": {
      handler() {
        if (this.triggerWatch) {
          this.fetchProducts();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.input-group-append {
  right: 0px;
}
.col.product-name.pl-0 div a {
  text-decoration: none;
}
.filters-selected {
  color: #000;
  padding: 5px 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
  background: #f2f2f2;
  font-weight: normal;
}
.filters-selected a {
  text-decoration: none;
}

.form-select {
  background-color: #fff !important;
}
select.decorated option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
  background: #ccc;
}
li.bubble_color {
  float: left;
  padding: 5px !important;
}
#grid-list-btn-container {
  text-align: right;
}
#selected-filters-list-top {
  padding: 10px 10px 10px 0px !important;
}

#selected-filters-list-top span {
  color: #000;
  padding: 10px 15px;
  margin-right: 10px;
  float: left;
  background: #f2f2f2;
  font-weight: normal;
  font-size: 12px;
}
#view-grid .product-wrapper {
  min-height: 250px !important;
  max-height: 500px;
}
.mt-10 {
  margin-top: 10px;
}

.bubble_color_wrapper {
  text-align: center;
  display: inline-block;
}

.bolder-size {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
}

.star-color {
  color: #f37f62;
}

.tbl_breakdown,
.tbl_breakdown tr {
  border: 1px solid #fff;
}

.tbl_breakdown {
  width: 350px;
}

.recently_viewed,
.recently_viewed .container {
  padding-left: 0px;
  padding-right: 0px;
}

.filter-image.filter-color {
  position: relative;
}
.product-card .card-header.not-logged .row {
    margin: 0rem;
}
.action div { width:100% }
@media (min-width: 992px) {
  .bolder-size {
    width: calc(25% + 50px);
  }
}
@media screen and (max-width: 767px) {
  .product-card .action :deep(.btn-theme) {
    margin-top: 4px !important;
  }
}
</style>
